#Layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: var(--document-height);
    overflow-x: hidden;
}

#Layout>div {
    flex-grow: 2;
}

#Layout footer {
    height: 300px;
}

.alerts {
    position: fixed;
    top: 1em;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 4000;
    pointer-events: none;
}

.blur {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 3000;
}

.login {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3000;
}