.widget {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    padding: 1em;
    background-color: white;
    border: 1px solid #CECECE;
}

.month {
    display: flex;
    flex-direction: row;
    height: 2em;
}

.month div {
    margin: auto;
}

.widget .month button {
    width: initial;
    height: 100%;
    padding: 0;
    aspect-ratio: 1 / 1;
}

.calendar {
    display: grid;
    grid-template-columns: repeat(7, 2.5em);
    grid-auto-rows: 2.5em;
    gap: 0em 0em;
    font-size: 0.8em;
    justify-items: stretch;
    align-items: center;
}

.calendar .header {
    color: rgb(150, 150, 150);
    text-align: center;
}

.calendar button {
    position: relative;
    background-color: white;
    border: none;
    padding: 0;
}

.calendar .weekend {
    border-radius: 0;
    background-color: rgb(235, 235, 235);
}

.calendar .selected {
    background-color: rgb(54, 54, 54);
    color: white;
}

.calendar .today  {
    border: 1px solid rgb(128, 128, 128);
}

.calendar .other_month {
    background-color: white;
    color: rgb(200, 200, 200);
}

.calendar button:hover::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    border-radius: inherit;
    background-color: rgba(0,0,0,0.1);
}