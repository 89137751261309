.auth {
    width: min(90%, 25em);
    box-sizing: border-box;
    font-size: 16px;
    background-color: white;
    
    border: 1px solid #CCC;
    border-radius: 0.5em;
    box-shadow: 1px 1px 2px #ccf;
    padding: 2em;
    background-color: #FFF;
}

.auth h1 {
    margin: 0;
}

.auth hr {
    height: 1px;
    background-color: #CCC;
    border: none;
    margin: 2em 0;
}

.auth form {
    display: flex;
    flex-direction: column;
}

.auth form :is(input, select) {
    border: solid #CCC 1px;
    padding: 1em;
    border-radius: 0.5em;
    margin: 0.5em 0;
    font-size: inherit;
}

.auth .submit {
    font-weight: 500;
    background-color: var(--primary);
    color: black;
    margin-top: 2em;
    margin-bottom: 0;
}

.center {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}