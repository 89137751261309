/* general styling */
.navbar {
    background-color: white;
    box-shadow: 2px 2px 4px #CECECE;
    position: sticky;
    top: 0px;
    z-index: 1000;
    height: 64px;
}

.navbar_items, .menu_items, .dropdown_items {
    margin: 0;
    padding: 0;
}

/* logo Styling */
.logo {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    font-weight: 300;
    margin: 0 1em;
}

.logo img {
    pointer-events: none;
    height: 60%;
    width: auto;
    filter: drop-shadow(2px 2px 2px rgba(32, 32, 256, 0.2));
}

.logo span {
    font-family: "Source Sans Pro";
    pointer-events: none;
    font-size: 1.25rem;
    margin-left: 0.5em;
    margin-bottom: 0.1em;
    font-weight: 300;
}

.logo a {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

/* styling for each major component of navbar */
.navbar_items {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 1em;
    height: 100%;
}

.navbar_items > * {
    min-height: 0;
}

/* styling for each dropdown header */
.menu_items {
    display: flex;
    flex-direction: row;
    color: rgba(0,0,0,0.5)
}

.menu_items > *:hover {
    color: rgba(0,0,0,1)
}

.menu_items > * > header {
    display: flex;
    height: 100%;
    padding: 0 1em;
    align-items: center;
    justify-content: center;
}

/* Styling of each dropdown menu */
.dropdown_items {
    display: none;
    flex-direction: column;
    position: absolute;
    box-shadow: inset 0px 4px 4px -2px #CECECE, 1px 1px 2px #CECECE;
    background-color: white;
}

.menu_items > *:hover > .dropdown_items {
    display: flex;
}

/* Styling of each link */
.dropdown_items > a {
    display: block;
    padding: 1em;
}

.dropdown_items > a:hover {
    background: rgba(0,0,0,0.1  );
}

.navbar :is(a:link, a:visited, a:hover, a:active) {
    text-decoration: none;
    color: rgba(0,0,0,1);
}

/* responsive styling */
@media screen and (max-width: 768px) {
    .logo {
        display: none;
    }

    .navbar_items {
        grid-template-columns: auto 1fr;
        justify-items: end;
    }
}