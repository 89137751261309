.active {
    margin-top: 1em;
}

.display {
    margin-top: 50px;
}

.display header {
    display: grid;
    row-gap: 1em;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(2, auto);
    grid-template-areas:
        "header header"
        "query data";
}

.display header h2 {
    grid-area: header;
}

.query_options,
.data_controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em 1em;
    height: 3.5em;
}

.query_options {
    grid-area: query;
}

.query_options input {
    width: 100%;
}

.data_controls {
    grid-area: data;
    width: fit-content;
    align-self: center;
    justify-self: end;
}

.data_controls .icon {
    padding: 0 1em;
}

.download {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    padding: 1em;
    border: none;
    border-radius: 100em;
    color: rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 200ms, color 200ms;
}

.download:hover {
    color: rgba(0, 0, 0, 1);
}

.download svg {
    height: 1em;
    aspect-ratio: 1/1;
}



@media screen and (max-width: 768px) {
    .display header {
        grid-template-areas:
            "header data"
            "query query";
    }

    .query_options {
        flex-direction: column;
        height: auto;
    }

    .query_options>* {
        width: 100%;
    }

    .data_controls {
        height: 2em;
    }
}