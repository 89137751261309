.tooltip {
    position: relative;
    display: inline-block;
    width: max-content;
    height: 100%;
}
  
/* Tooltip text */
.tooltip .tooltiptext {
    font-size: 0.75em;
    visibility: hidden;
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 1em;
    border-radius: 0.25em;
   
    position: absolute;
    z-index: 1;
}
  
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tooltip .tooltiptext {
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0);
}

.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}