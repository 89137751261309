.card {
  border: 1px solid #CCC;
  border-radius: 0.5em;
  box-shadow: 1px 1px 2px #ccf;
  padding: 2em;
  background-color: #FFF;
}

.section {
  margin: 1em;
  margin-top: 50px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

button,
input[type="submit"] {
  border: 1px solid grey;
  cursor: pointer;
}

button:hover,
input[type="submit"]:hover {
  background-image: linear-gradient(rgba(255, 255, 255, 0.5) 0 0);
}

button:active,
input[type="submit"]:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
}

input[type="date"] {
  border: 1px solid grey;
}

@media screen and (max-width: 768px) {
  .card {
    padding: 2em 1em;
    border-radius: 0;
  }

  .section {
    margin: 0;
    margin-top: 50px;
  }
}