.announcements > div {
    margin: 2em 0;
    border: 1px solid #CECECE;
    padding: 2em;
    box-shadow: 1px 2px 4px rgba(0,0,255,0.1);
    border-radius: 0.25em;
}

.announcements > div > header {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}

.announcements > div span {
    color: grey;
}

.announcements > div p {
    margin-block-end: 0;
}