.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.form {
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
}

.form.mr {
    row-gap: 1em;
}

.form.db {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 1em;
}

.form.db input {
    margin: auto auto auto 0;
}

.group {
    display: flex;
    flex-direction: column;
    gap: 0.25em;
}

.group>* {
    flex-grow: 1;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 0.25em;
}

.row>* {
    flex-grow: 1;
}

.group>header {
    font-weight: bold;
}

:is(.row, .group) :is(input, textarea, button) {
    width: 100%;
}

.date {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 10em;
}

.delete {
    flex-grow: 0;
}

.site_selector {
    width: 300px;
}

.report_type_selector,
.report_type_button {
    width: 400px;
}

.ids {
    flex-grow: 1;
}

@media screen and (max-width: 768px) {
    .form {
        row-gap: 2em;
    }

    .absent_report .row {
        flex-direction: column;
    }

    .absent_report .date {
        flex-basis: auto;
    }
}

@media screen and (max-width: 384px) {
    .swab_count .row {
        flex-direction: column;
    }

    .swab_count .date {
        flex-basis: auto;
    }
}