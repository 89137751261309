.input {
    height: 100%;
    padding: 1em;
    box-sizing: border-box;
    border: 1px solid #CECECE;
    border-radius: 0.25em;
    font-size: inherit;
}

.input.readonly {
    pointer-events: none;
    color: #666;
    background-color: #F8F8F8;
}

.input.valid {
    border: 1px solid #397d39;
}

.input.valid:focus {
    outline: 1px solid #397d39;
}

.textarea {
    resize: none;
}

.select {
    position: relative;
    font-size: inherit;
}

.select .input:hover {
    cursor: pointer;
}

.value {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 1em;
    height: 1em;
    overflow: hidden;
}

.placeholder {
    color: #CECECE;
}

.options {
    position: absolute;
    left: 0px;
    right: 0px;
    border: 1px solid #CECECE;
    font-size: inherit;
    z-index: 1;
}

.option {
    background-color: white;
    padding: 1em;
    cursor: pointer;
}

.option:hover {
    background-color: #CECECE;
}

.button {
    border: 1px solid #CECECE;
    border-radius: 0.25em;
    font-size: inherit;
    height: 100%;
    padding: 1em;
}

.button:hover {
    cursor: pointer;
}

.picker {
    position: relative;
    height: 100%;
}

.picker:focus {
    outline: none;
}

.picker .calendar {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    z-index: 100;
}

.picker .calendar.show {
    opacity: 1;
    pointer-events: all;
}

.picker .calendar.left {
    left: 0px;
}

.picker .calendar.right {
    right: 0px;
}

.calendar_button {
    position: absolute;
    height: 100%;
    top: 0px;
    right: 0px;
    padding: 0 1em;
}

.hidden {
    opacity: 0%;
}

.icon_button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.icon_button:hover {
    color: rgba(0, 0, 0, 1);
}

.icon_button svg {
    height: 1em;
}