.input form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 1em;
    height: 3em;
}

.input input, select {
    font-size: inherit;
}

.input input[type=file] {
    flex-grow: 1;
    margin: auto;
}