.alert {
    position: relative;
    margin: 0.5em 0;
    border-radius: 0.25em;
    box-shadow: 2px 2px 4px #CECECE;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 90%;
    pointer-events: auto;
}

.alert .close_button {
    padding: 1em;
}

.alert span {
    margin: 1em;
    margin-right: 0;
}

.alert span {
    display: block;
}

.alert[data-category="ok"] {
    color: rgb(32, 128, 32);
    background-color: rgb(225, 255, 225);
}

.alert[data-category="action"] {
    color: rgb(128, 32, 128);
    background-color: rgb(255, 225, 255);
}

.alert[data-category="warn"] {
    color: rgb(128, 128, 32);
    background-color: rgb(255, 255, 225);
}

.alert[data-category="error"] {
    color: rgb(128, 32, 32);
    background-color: rgb(255, 225, 225);
}

.timer {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-radius: 0.25em;
}

.alert[data-category="ok"] .timer {
    background-color: rgb(32, 128, 32);
}

.alert[data-category="action"] .timer {
    background-color: rgb(128, 32, 128);
}

.alert[data-category="warn"] .timer {
    background-color: rgb(128, 128, 32);
}

.alert[data-category="error"] .timer {
    background-color: rgb(128, 32, 32);
}