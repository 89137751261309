.table {
    width: 100%;
    border-collapse: collapse;
}

.table :is(td, th) {
    padding: 1em 0.25em;
    text-align: left;
}

.table .highlight:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.table :is(tbody, thead) {
    border-bottom: solid rgba(0, 0, 100, 0.25) 1px;
}

.additional_fields {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    box-shadow: inset 0px 8px 6px -6px rgba(0, 0, 0, 0.2);
}

.additional_fields .additional_field_row {
    display: contents;
}

.additional_fields .additional_field_row>* {
    padding: 1em 2em 1em 0.25em;
}

.additional_fields .additional_field_row .value {
    white-space: pre-line;
}

.additional_fields .additional_field_row:hover>* {
    background-color: rgba(0, 0, 0, 0.05);
}

.additional_fields .field {
    font-weight: bold;
}

.table .collapsed_row {
    padding: 0px;
    overflow: hidden;
}

.collapsed_row .content {
    overflow-y: auto;
    max-height: 75vh;
}

.page_controls {
    display: flex;
    height: 3em;
    gap: 1em;
    justify-content: flex-end;
    font-size: 75%;
}

.page_controls:last-child {
    padding-top: 1em;
}

.page_controls>* {
    padding: 0.5em 1em;
}

.page_controls input {
    width: 5em;
    text-align: center;
}

.page_controls input::-webkit-outer-spin-button,
.page_controls input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

@media screen and (max-width: 768px) {
    .table {
        font-size: 14px;
    }

    .page_controls {
        justify-content: center;
    }

    .page_controls>*,
    .page_controls input {
        width: 100%
    }
}