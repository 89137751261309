:root {
  --primary: #FDC00A;
  --document-height: 100%;
}

body {
  background-color: rgba(0,0,128,0.05);
  margin: 0;
}

body, button, input, textarea {
  font-family: "Rubik";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, button, select, textarea {
  appearance: none;
}